import React, { useEffect, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const EditUserPopup = ({ user, onUpdate, onCancel }) => {
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validationSchema = Yup.object({
    userName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    role: Yup.string()
      .oneOf(['admin', 'user'], 'Invalid role')
      .required('Required'),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z]{3,})(?=.*\d{3,}).{6,}$/,
        'Password must contain at least 3 letters and 3 numbers and be 6 characters or more'
      )
      .nullable(),
  });

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      <div className="flex items-center justify-center min-h-screen w-[500px]">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          ref={modalRef}
          className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Edit User
            </h3>
            <Formik
              initialValues={{ ...user, password: '' }} // Set password to an empty string
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                if (values.password === '') {
                  // Remove password from values so if the pass is not changed it will not be updated
                  delete values.password;
                }
                onUpdate(values); // Pass the updated values for submission
                setSubmitting(false);
              }}
            >
              <Form>
                {/* Form Fields */}
                <Form>
                  <div className="mb-4">
                    <label
                      htmlFor="userName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username:
                    </label>
                    <Field
                      name="userName"
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email:
                    </label>
                    <Field
                      name="email"
                      type="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Role:
                    </label>
                    <Field
                      as="select"
                      name="role"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </Field>
                    <ErrorMessage
                      name="role"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    {/* Password Field */}
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Password:
                    </label>
                    <Field
                      name="password"
                      type="password"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                </Form>

                {/* Buttons */}
                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-red-400 to-red-600 text-white hover:from-red-600 hover:to-red-700 focus:outline-none focus:ring focus:ring-red-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-cyan-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserPopup;
