import React from 'react';
import { FaTimes, FaFileExport } from 'react-icons/fa';
import xmlbuilder from 'xmlbuilder';
import EmotionTimeline from './EmotionTimeline';
import EmotionPiechart from './EmotionPiechart';

const VideoDetailsPopup = ({ VideoDetails, closePopup }) => {
  //function for constructiong the xml
  const generateXml = () => {
    const root = xmlbuilder.create('VideoDetails');
    root.att('id', VideoDetails._id);
    root.ele('date', {}, VideoDetails.result_date);
    root.ele('refers_to', {}, VideoDetails.refers_to);
    root.ele('youtube_video_url', {}, VideoDetails.videoData.videoUrl);
    root.ele('username', {}, VideoDetails.videoData.username);
    root.ele(
      'videoEmotionAnalysis',
      {},
      JSON.stringify(VideoDetails.result_details)
    );

    return root.end({ pretty: true });
  };

  const xmlData = generateXml();

  //function for exporting the xml
  const exportXmlData = () => {
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor and click on it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${VideoDetails.refers_to}.xml`;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up URL object
    URL.revokeObjectURL(url);
  };

  // Function to stop click event propagation
  const stopClickPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
      onClick={closePopup}
    >
      <div
        className="bg-white p-5 rounded-lg w-full max-w-7xl relative"
        onClick={stopClickPropagation}
      >
        <div className="content flex flex-column gap-4 mb-4">
          <div className="w-2/3">
            <h2 className="text-2xl mb-2 border-b-[2px]">
              VideoDetails Emotion Analysis Details
            </h2>
            <p className="text-xs text-gray-500 pb-2 mb-2 border-b-[1px]">
              <strong>ID:</strong> {VideoDetails._id}
              <br></br>
              <strong>Date:</strong> {VideoDetails.result_date}
              <br></br>
              <strong>Refers to:</strong> {VideoDetails.refers_to}
            </p>

            <div className="flex items-center mb-2">
              <p className="mr-4">
                <strong>Username:</strong> {VideoDetails.videoData.username}
              </p>
              <p className="mr-4">
                <strong>Youtube Video ID:</strong>{' '}
                {VideoDetails.videoData.videoId}
              </p>
            </div>
            <div className="video-container mb-4">
              <p>
                <strong>Visited Video:</strong>{' '}
                <a
                  href={VideoDetails.videoData.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {VideoDetails.videoData.videoUrl}
                </a>
              </p>
              <iframe
                className="youtube-iframe"
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${VideoDetails.videoData.videoId}`}
                allowFullScreen
              ></iframe>
            </div>
            <p>
              <strong>Analysis Video:</strong>{' '}
              <a
                href={VideoDetails.videoData.videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {VideoDetails.videoData.videoUrl}
              </a>
            </p>
            <div className="my-2">
              <EmotionTimeline
                videoEmotionAnalysis={VideoDetails.result_details}
              />
            </div>
          </div>
          <div className="flex items-center justify-center my-2">
            <EmotionPiechart
              videoEmotionAnalysis={VideoDetails.result_details}
            />
          </div>
        </div>

        <button
          className="absolute top-2 right-2 flex items-center justify-center p-2 text-white bg-gray-500 rounded-full hover:bg-gray-700 focus:outline-none"
          onClick={closePopup}
        >
          <FaTimes />
        </button>
        <button
          className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          onClick={exportXmlData}
        >
          <FaFileExport className="mr-1" />
          Export XML
        </button>
      </div>
    </div>
  );
};

export default VideoDetailsPopup;
