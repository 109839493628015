import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FiArrowDown,
  FiArrowUp,
  FiBarChart,
  FiHome,
  FiPieChart,
  FiPaperclip,
  FiInfo,
  FiCamera,
  FiUser,
} from 'react-icons/fi';
import { useAuth } from '../AuthContext';

const Sidebar = () => {
  const { role } = useAuth();
  const [isExpandedInfo, setIsExpandedInfo] = useState(false);
  const [isExpandedStatisticsInfo, setIsExpandedStatisticsInfo] =
    useState(false);

  const handleToggleInfo = () => {
    setIsExpandedInfo(!isExpandedInfo);
  };

  const handleToggleStatisticsInfo = () => {
    setIsExpandedStatisticsInfo(!isExpandedStatisticsInfo);
  };

  return (
    <div
      className="custom-scrollbar w-full md:w-1/6 bg-gradient-to-br from-blue-600 to-indigo-700 absolute ease-in-out transition-all duration-300 z-30 transform overflow-y-auto shadow-lg"
      style={{ height: 'calc(100vh - 7.5rem - 4px)' }}
    >
      <ul className="px-2 py-4">
        <li>
          <Link
            to="/dashboard"
            className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300"
          >
            <FiHome className="w-8 h-5 mr-4" />
            Dashboard
          </Link>
        </li>

        <li>
          <Link
            to="/monitoring"
            className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300"
          >
            <FiCamera className="w-8 h-5 mr-4" />
            On demand video Grabber
          </Link>
        </li>

        <li>
          <Link
            to="/results"
            className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300"
          >
            <FiPieChart className="w-8 h-5 mr-4" />
            Results
          </Link>
        </li>

        {role === 'admin' && (
          <li>
            <a
              onClick={handleToggleStatisticsInfo}
              className="flex items-center justify-between space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300 cursor-pointer"
            >
              <div className="flex items-center space-x-2">
                <FiBarChart className="w-8 h-5 mr-2" />
                <span className="text-lg font-medium">Statistics</span>
              </div>
              {isExpandedStatisticsInfo ? (
                <FiArrowUp className="text-lg" />
              ) : (
                <FiArrowDown className="text-lg" />
              )}
            </a>
            <ul
              className={`pl-10 transition-all duration-300 ease-in-out ${
                isExpandedStatisticsInfo ? 'max-h-40' : 'max-h-0'
              } overflow-hidden`}
            >
              <li>
                <Link
                  to="/statistics/by-video-id"
                  className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200text-md text-white hover:text-orange-500"
                >
                  Statistics by video url
                </Link>
              </li>

              <li>
                <Link
                  to="/statistics/by-user-email"
                  className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 text-md text-white hover:text-orange-500"
                >
                  Statistics by user email
                </Link>
              </li>
            </ul>
          </li>
        )}

        {role === 'admin' && (
          <li>
            <Link
              to="/logs"
              className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300"
            >
              <FiPaperclip className="w-8 h-5 mr-4" />
              Logs
            </Link>
          </li>
        )}

        {role === 'admin' && (
          <li>
            <Link
              to="/users"
              className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300"
            >
              <FiUser className="w-8 h-5 mr-4" />
              Users
            </Link>
          </li>
        )}

        <li>
          <a
            onClick={handleToggleInfo}
            className="flex items-center justify-between space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-500 text-xl text-white hover:text-orange-500  border-b border-indigo-300 cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <FiInfo className="w-8 h-5 mr-2" />
              <span className="text-lg font-medium">Extras</span>
            </div>
            {isExpandedInfo ? (
              <FiArrowUp className="text-lg" />
            ) : (
              <FiArrowDown className="text-lg" />
            )}
          </a>
          <ul
            className={`pl-10 transition-all duration-300 ease-in-out ${
              isExpandedInfo ? 'max-h-40' : 'max-h-0'
            } overflow-hidden`}
          >
            <li>
              <a
                href="/extras/chrome-extension/face_monitoring_chrome_extension.zip"
                target="_blank"
                className="block py-2 px-4 rounded transition duration-200 text-md text-white hover:text-orange-500 "
              >
                Chrome Extension
              </a>
            </li>

            <li>
              <a
                href="/extras/android_app/face_monitoring_android_app.zip"
                className="block py-2 px-4 rounded transition duration-200 text-md text-white hover:text-orange-500 "
              >
                Android APP
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
