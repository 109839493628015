import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/LoginPage';
import ResultsPage from './Pages/ResultsPage';
import DashboardPage from './Pages/DashBoardPage';
import ProfilePage from './Pages/ProfilePage';
import LogPage from './Pages/LogPage';
import MonitoringPage from './Pages/MonitoringPage';
import StatisticsByEmailPage from './Pages/StatisticsByEmailPage';
import StatisticsByVideoIdPage from './Pages/StatisticsByVideoIdPage';
import PrivateRoute from './PrivateRoute';
import { AuthProvider, useAuth } from './AuthContext';
import UsersPage from './Pages/UsersPage';
import { parseJwt } from './utils/utils';
import { useNavigate } from 'react-router-dom';

function App() {
  return (
    <AuthProvider>
      <Router basename="/">
        <RoutesComponent />
      </Router>
    </AuthProvider>
  );
}

function RoutesComponent() {
  const { role, logOut } = useAuth();
  const navigate = useNavigate();

  //code to check if the token is expired
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken) {
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // Token is expired
          logOut();
          navigate('/');
        }
      }
    }
  }, [logOut, navigate]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/monitoring" element={<MonitoringPage />} />
      <Route
        path="/results"
        element={
          <PrivateRoute>
            <ResultsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      {/* {role === 'admin' && ( */}
      <Route
        path="/logs"
        element={
          <PrivateRoute>
            <LogPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      {/* )} */}
      <Route
        path="/statistics/by-user-email"
        element={
          <PrivateRoute>
            <StatisticsByEmailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/statistics/by-video-id"
        element={
          <PrivateRoute>
            <StatisticsByVideoIdPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
