import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useAuth } from '../AuthContext'; // import the useAuth hook
import * as Yup from 'yup';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { logEvent } from '../utils/utils.js';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { logIn, logOut } = useAuth(); // get the logIn and logout function from useAuth

  const handleOnSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName: values.username,
          password: values.password,
        }),
      });

      const data = await response.json();

      if (response.status === 200 && data.status === 'success') {
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('userEmail', data.data.userEmail);
        localStorage.setItem('userName', data.data.userName);
        localStorage.setItem('userId', data.data.id);
        logIn();
        navigate('/dashboard');
      } else {
        setErrors({ invalidCredentials: 'Invalid username or password' });
        logEvent(
          'client_error',
          'Invalid username or password in the login page',
          ''
        );
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ invalidCredentials: 'An error occurred. Please try again.' });
      logEvent(
        'client_error',
        'An error occurred in the login page: ' + error.message,
        ''
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-500 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-xl">
        <img src={logo} className="mx-auto h-20 w-auto" alt="Logo" />

        <h3 className="mt-6 text-center text-3xl font-bold text-gray-800">
          Welcome Back
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form className="mt-8 space-y-6">
              <label className="block text-blue-700 text-sm font-bold mb-2">
                Username:
                <Field
                  type="text"
                  name="username"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-blue-300 placeholder-blue-500 text-blue-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600 text-sm mt-2"
                />
              </label>

              <label className="block text-blue-700 text-sm font-bold mb-2">
                Password:
                <Field
                  type="password"
                  name="password"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-blue-300 placeholder-blue-500 text-blue-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600 text-sm mt-2"
                />
              </label>

              <button
                type="submit"
                disabled={isSubmitting}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300"
              >
                Login
              </button>

              {errors.invalidCredentials && (
                <p className="text-red-600 text-sm mt-2">
                  {errors.invalidCredentials}
                </p>
              )}
            </Form>
          )}
        </Formik>
        <Link to="/monitoring" className="mt-4">
          <button className="group relative w-full flex justify-center mt-4 py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-300">
            Feed the application with video capturing
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
