import React, { useState, useEffect } from 'react';
import { FaVideo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DashVideoInfoCard = () => {
  const [totalVideos, setTotalVideos] = useState(0);
  const [isLoading, setIsLoading] = useState(''); // State to track loading status

  useEffect(() => {
    let url = `${apiBaseUrl}/api/results`;
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setTotalVideos([...data.data.results].length);
        setIsLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false); // Stop loading if there's an error
        setTotalVideos(0);
      });
  }, [totalVideos]);

  return (
    <Link
      to={'/results'}
      className="p-6 min-w-full md:min-w-[16.5%] max-w-sm mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl shadow-lg flex items-center space-x-4 hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1"
    >
      <div className="flex-shrink-0">
        <FaVideo className="h-12 w-12 text-white" />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-grey-500"></div>
        </div>
      ) : (
        <div>
          <h4 className="text-lg text-white font-semibold">
            Total videos Analysis: {totalVideos}
          </h4>
        </div>
      )}
    </Link>
  );
};

export default DashVideoInfoCard;
