import React from 'react';
import { FaTimes } from 'react-icons/fa';

const FilterLogsComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="relative flex items-center">
      <input
        id="search"
        type="text"
        placeholder="Filter By Message"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {filterText && (
        <button
          type="button"
          onClick={onClear}
          className="absolute right-0 mr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <FaTimes />
        </button>
      )}
    </div>
  </>
);

export default FilterLogsComponent;