import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FaChartBar } from 'react-icons/fa';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DashVideoMonthCard = () => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
    },
  });

  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch request is sent
    fetch(`${apiBaseUrl}/api/results/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          updateChartData(data.data.results);
          setIsLoading(false); // Stop loading after data is fetched
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Stop loading if there's an error
      });
  }, []);

  const updateChartData = (data) => {
    const monthCounts = Array(12).fill(0);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    data.forEach((item) => {
      const monthIndex = new Date(item.result_date).getMonth();
      monthCounts[monthIndex]++;
    });

    setChartData({
      series: [
        {
          name: 'Videos',
          data: monthCounts,
        },
      ],
      options: {
        ...chartData.options,
        xaxis: { categories: months },
      },
    });
  };

  return (
    <div className="p-6 max-w-screen-md mx-auto bg-white rounded-xl shadow-md hover:bg-gray-100 transition duration-300 w-full">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <FaChartBar className="h-12 w-12 text-blue-500" />
        </div>
        <div className="flex-grow">
          <h4 className="text-lg text-gray-500 font-semibold">
            Video Uploads by Month
          </h4>
          {isLoading ? (
            <div className="flex justify-center items-center h-96">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          ) : (
            <div className="mt-4">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashVideoMonthCard;
