import React from 'react';
import { FaTimes, FaFileExport } from 'react-icons/fa';
import xmlbuilder from 'xmlbuilder';

const LogPopup = ({ log, closePopup }) => {
  // Function for constructing the XML
  const generateXml = () => {
    const root = xmlbuilder.create('log');
    root.att('id', log._id);
    root.ele('log_date', {}, new Date(log.log_date).toLocaleString());
    root.ele('refers_to', {}, log.refers_to);
    root.ele('log_type', {}, log.log_details.log_type);
    root.ele('log_message', {}, log.log_details.log_message);
    return root.end({ pretty: true });
  };

  const xmlData = generateXml();

  // Function for exporting the XML
  const exportXmlData = () => {
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor and click on it to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${log._id}-${new Date(log.log_date).toLocaleString()}.xml`;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up URL object
    URL.revokeObjectURL(url);
  };

  // Function to stop click event propagation
  const stopClickPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
      onClick={closePopup}
    >
      <div
        className="bg-white p-5 rounded-lg w-full max-w-4xl relative"
        onClick={stopClickPropagation}
      >
        <h2 className="text-2xl mb-2 border-b-[2px]">Log Details</h2>
        <div className="content mb-4">
          <p className="text-md text-gray-500 pb-2 mb-2 border-b-[1px]">
            <strong>ID:</strong> {log._id}
            <br></br>
            <strong>Log Date:</strong> {new Date(log.log_date).toLocaleString()}
            <br></br>
            <strong>Refers To:</strong> {log.refers_to}
            <br></br>
            <strong>Log Type:</strong> {log.log_details.log_type}
            <br></br>
            <strong>Log Message:</strong> {log.log_details.log_message}
          </p>
        </div>
        <button
          className="absolute top-2 right-2 flex items-center justify-center p-2 text-white bg-gray-500 rounded-full hover:bg-gray-600 focus:outline-none"
          onClick={closePopup}
        >
          <FaTimes />
        </button>
        <button
          className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-cyan-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          onClick={exportXmlData}
        >
          <FaFileExport className="mr-1" />
          Export XML
        </button>
      </div>
    </div>
  );
};

export default LogPopup;
