import React, { useEffect, useRef } from 'react';

const DeleteConfirmationPopup = ({ user, onDelete, onCancel }) => {
  const modalRef = useRef();

  // Close popup if clicked outside of it
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <div
        ref={modalRef}
        className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                Are you sure you want to delete this user?
              </h3>
              <div className="mt-4">
                <p>
                  <strong>Username:</strong> {user.userName}
                </p>
                <p>
                  <strong>Email:</strong> {user.email}
                </p>
                <p>
                  <strong>Role:</strong> {user.role}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex">
          <button
            onClick={() => onDelete(user)}
            className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-cyan-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          >
            Confirm
          </button>
          <button
            onClick={onCancel}
            className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-red-400 to-red-600 text-white hover:from-red-600 hover:to-red-700 focus:outline-none focus:ring focus:ring-red-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
