import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const logEvent = async (logType, logMessage, refersTo = '') => {
  try {
    await axios.post(`${apiBaseUrl}/api/logs`, {
      refers_to: refersTo,
      log_details: {
        log_type: logType,
        log_message: logMessage,
      },
    });
  } catch (err) {
    console.error('Error logging event:', err);
  }
};

// Function to process your data and extract required information
export const processVideoData = (videoData) => {
  return videoData.map((data) => {
    const dominantEmotion = getDominantEmotion(data.Face.Emotions);
    return {
      timestamp: data.Timestamp,
      emotion: dominantEmotion.Type.toLowerCase(),
    };
  });
};

// Helper function to find the emotion with the highest confidence
export const getDominantEmotion = (emotions) => {
  return emotions.reduce((prev, current) => {
    return prev.Confidence > current.Confidence ? prev : current;
  });
};

export function getColor(emotion) {
  switch (emotion) {
    case 'happy':
      return '#facc15'; // yellow-400
    case 'neutral':
      return '#9ca3af'; // gray-400
    case 'sad':
      return '#60a5fa'; // blue-400
    case 'angry':
      return '#ef4444'; // red-500
    case 'calm':
      return '#34d399'; // green-400
    case 'excited':
      return '#a78bfa'; // purple-400
    case 'bored':
      return '#d1d5db'; // gray-300
    case 'confused':
      return '#d1d5db'; // gray-300
    case 'joyful':
      return '#f472b6'; // pink-400
    case 'tired':
      return '#000000'; // black
    case 'anxious':
      return '#fb923c'; // orange-400
    case 'surprised':
      return '#93c5fd'; // blue-300
    case 'fear':
      return '#fde047'; // yellow-300
    case 'disgusted':
      return '#14b8a6'; // teal-400
    default:
      return '#ffffff'; // white
  }
}

//helper function to decode the JWT token
export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

// Function to get YouTube video ID from URL
export function getVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return null;
  }
}
