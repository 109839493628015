import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaFileExport, FaPencilAlt, FaTrash, FaPlus } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import FilterComponent from '../results/FilterComponent';
import xmlbuilder from 'xmlbuilder'; // import xmlbuilder
import DeleteConfirmationPopup from './DeleteConfirmationPopup'; // import DeleteConfirmationPopup
import EditUserPopup from './EditUserPopup'; // import EditUserPopup
import AddUserPopup from './AddUserPopup'; // import AddUserPopup
import { logEvent } from '../../utils/utils.js';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Export = ({ onExport }) => (
  <button
    onClick={(e) => onExport(e)}
    className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-cyan-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
  >
    <FaFileExport className="inline mr-2" />
    Export Raw Data to XML
  </button>
);

const UsersDataTable = () => {
  const [users, setUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State to track loading status

  // fetch the users data from the server
  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch request is sent
    const token = localStorage.getItem('token'); // Retrieve the token
    console.log('token', token);
    if (token) {
      fetch(`${apiBaseUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            logEvent('client_error', 'Unauthorize call in the users page');
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setUsers(data.data.users);
          setIsLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error('Error:', error);
          setUsers([]); // Set users to an empty array in case of an error
          logEvent(
            'client_error',
            'Users page error with the fetched data having the following message: ' +
              error
          );
          setIsLoading(false); // Stop loading after data is fetched
        });
    }
  }, []);

  // delete the user and refresh the datatable staying in the same page without refreshing the page
  const handleDeleteUser = async (userToDelete) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await axios.delete(`${apiBaseUrl}/api/users/${userToDelete._id}`, config);

      setUsers(users.filter((user) => user._id !== userToDelete._id));
      setDeleteUser(null);
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  const handleDeleteButton = (row) => {
    setDeleteUser(row);
  };

  const handleAddUser = async (newUser) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await axios.post(
        `${apiBaseUrl}/api/users`,
        newUser,
        config
      );

      if (response.status !== 201) {
        alert(`Error: ${response.status}`);
        return;
      }

      // Fetch updated list of users from the server
      const updatedUsersResponse = await axios.get(
        `${apiBaseUrl}/api/users`,
        config
      );

      if (updatedUsersResponse.status !== 200) {
        alert(`Error: ${updatedUsersResponse.status}`);
        return;
      }

      // Update the state with the updated list of users
      setUsers(updatedUsersResponse.data.data.users);
      setAddUser(false);
    } catch (error) {
      console.error('Failed to add user:', error);
      alert(`Error: ${error.message}`);
    }
  };

  const AddUserButton = () => (
    <button
      onClick={() => setAddUser(true)}
      className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-cyan-400 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
    >
      <FaPlus className="inline mr-2" /> Add User
    </button>
  );

  const handleEditButton = (row) => {
    setEditUser(row);
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      // replace with your actual API endpoint
      await axios.patch(
        `${apiBaseUrl}/api/users/${updatedUser._id}`,
        updatedUser,
        config
      );

      setUsers(
        users.map((user) => (user._id === updatedUser._id ? updatedUser : user))
      );
      setEditUser(null);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  //columns to init the data table
  const columns = [
    { name: 'ID', selector: '_id', sortable: true },
    { name: 'Username', selector: 'userName', sortable: true },
    { name: 'Email', selector: 'email', sortable: true },
    { name: 'Role', selector: 'role', sortable: true },
    {
      cell: (row) => (
        <button
          className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-purple-600 hover:to-purple-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          onClick={() => handleEditButton(row)}
        >
          <FaPencilAlt className="h-3 w-3 text-white mr-2" />
          <span>Edit</span>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          className="flex items-center px-2 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-red-400 to-red-600 text-white hover:from-red-600 hover:to-red-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
          onClick={() => handleDeleteButton(row)}
        >
          <FaTrash className="h-3 w-3 text-white mr-2" />
          <span>Delete</span>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = users.filter(
    (item) =>
      item.userName &&
      item.userName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const downloadXML = (users) => {
    const root = xmlbuilder.create('root');

    if (users.length > 0) {
      users.forEach((row, index) => {
        const item = root.ele('item');
        Object.keys(row).forEach((key) => {
          item.ele(key, {}, row[key]);
        });
      });
    }

    const xmlString = root.end({ pretty: true });

    // Create a downloadable blob
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.xml');
    document.body.appendChild(link);
    link.click();
  };
  const actionsMemo = useMemo(
    () => (
      <>
        <AddUserButton />
        <Export onExport={() => downloadXML(users)} />
      </>
    ),
    [users]
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor: '#202124', // dark background for the header
        color: 'white', // text color for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding
        paddingRight: '8px',
      },
    },
    pagination: {
      style: {
        padding: '20px', // Add padding around pagination
        color: '#004085', // Text color for pagination
        fontSize: '1.1rem', // Larger font for pagination
        justifyContent: 'center', // Center the pagination
      },
      pageButtonsStyle: {
        borderRadius: '50%', // Round the page buttons
        height: '40px', // Height of the page buttons
        width: '40px', // Width of the page buttons
        padding: '8px', // Padding within page buttons
        margin: '8px', // Margin around page buttons
        cursor: 'pointer', // Cursor to pointer on hover
        transition: 'background-color 0.3s', // Smooth transition for hover effect
        color: '#007bff', // Color of page button icons
        fill: '#007bff', // Fill color for SVG icons
      },
    },
  };

  return (
    <div className="w-full md:w-4/5 mx-auto p-4 mt-8 mb-20 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600 mb-4">
        User List
      </h2>
      {!isLoading ? (
        <DataTable
          title=""
          customStyles={customStyles}
          columns={columns}
          data={filteredItems}
          highlightOnHover
          pointerOnHover
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          actions={actionsMemo}
        />
      ) : (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      )}
      {deleteUser && (
        <DeleteConfirmationPopup
          user={deleteUser}
          onDelete={handleDeleteUser}
          onCancel={() => setDeleteUser(null)}
        />
      )}
      {editUser && (
        <EditUserPopup
          user={editUser}
          onUpdate={handleUpdateUser}
          onCancel={() => setEditUser(null)}
        />
      )}
      {addUser && (
        <AddUserPopup
          onAdd={handleAddUser}
          onCancel={() => setAddUser(false)}
        />
      )}
    </div>
  );
};

export default UsersDataTable;
