import React from 'react';
import { processVideoData, getColor } from '../../utils/utils';

const EmotionTimeline = ({ videoEmotionAnalysis }) => {
  const processedData = processVideoData(videoEmotionAnalysis);
  console.log(processedData);

  return (
    <div className="relative h-36 overflow-x-scroll px-8 my-6 rounded-xl border border-grey-100">
      {processedData.map((entry, index) => (
        <div
          key={index}
          className="absolute bottom-0 flex flex-col items-center"
          style={{ left: `${entry.timestamp * 0.02}%` }}
        >
          <div className="text-xs font-semibold mt-3">
            {' '}
            {(entry.timestamp / 1000).toFixed(2)}s
          </div>
          <div
            className={`h-20 w-1 rounded-lg`}
            style={{ backgroundColor: getColor(entry.emotion) }}
          ></div>{' '}
          <div
            className={`text-xs font-semibold mt-1 rounded px-1 py-0.5 text-white`}
            style={{ backgroundColor: getColor(entry.emotion) }}
          >
            {entry.emotion}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmotionTimeline;
