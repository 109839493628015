import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-gray-600 to-gray-900 py-4 text-white text-center shadow-md">
      <div className="container mx-auto px-4">
        <p className="text-sm font-medium">
          Athanasios Diamantis MPSP21013 &copy; 2023
        </p>
      </div>
    </footer>
  );
};

export default Footer;
