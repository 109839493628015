import React from 'react';
import EmotionPiechart from './EmotionPiechart';

const VideoStats = ({ data, videoId, showAvgs }) => {
  if (
    !data.overallAverageEmotions ||
    Object.keys(data.overallAverageEmotions).length === 0
  ) {
    return <div className="text-center mt-20">No results found.</div>;
  }

  return (
    <div className="flex flex-col md:flex-row gap-12 content-center justify-items-center justify-center mt-20">
      {data && videoId && (
        <div className="p-4 rounded bg-white my-4">
          <p className="font-bold">For the video: {videoId}</p>
          <iframe
            className="youtube-iframe"
            title="YouTube Video"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
          ></iframe>
        </div>
      )}

      {showAvgs && (
        <div className="flex flex-col space-y-2 my-4">
          <p className="font-bold">General</p>
          <p>
            <span className="font-semibold">Average Low Age:</span>{' '}
            {data.overallAverageAgeLow.toFixed(1)}
          </p>
          <p>
            <span className="font-semibold">Average High Age:</span>{' '}
            {data.overallAverageAgeHigh.toFixed(1)}
          </p>
          <p>
            <span className="font-semibold">Males:</span> {data.totalMaleCount}
          </p>
          <p>
            <span className="font-semibold">Females:</span>{' '}
            {data.totalFemaleCount}
          </p>
        </div>
      )}

      {data.overallAverageEmotions && (
        <div>
          <EmotionPiechart videoEmotionAnalysis={data.overallAverageEmotions} />
        </div>
      )}
    </div>
  );
};

export default VideoStats;
