import React, { useState, useEffect } from 'react';
import { FiPaperclip } from 'react-icons/fi';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DashUserLogCard = ({ users }) => {
  const [logs, setLogs] = useState(0);
  const [srErrorLogs, setSrErrorLogs] = useState(0);
  const [clErrorLogs, setClErrorLogs] = useState(0);
  const [infoLogs, setIfoLogs] = useState(0);
  const [successLogs, setSuccessLogs] = useState(0);
  const [isLoading, setIsLoading] = useState(''); // State to track loading status

  useEffect(() => {
    let url = `${apiBaseUrl}/api/logs`;
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLogs(data.data.logs.length);
        setSuccessLogs(
          data.data.logs.filter(
            (item) => item.log_details.log_type === 'success'
          ).length
        );
        setIfoLogs(
          data.data.logs.filter((item) => item.log_details.log_type === 'info')
            .length
        );
        setSrErrorLogs(
          data.data.logs.filter(
            (item) => item.log_details.log_type === 'server_error'
          ).length
        );
        setClErrorLogs(
          data.data.logs.filter(
            (item) => item.log_details.log_type === 'client_error'
          ).length
        );
        setIsLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false); // Stop loading if there's an error
        setGuestUsers(0);
      });
  }, [logs]);

  return (
    <div className="p-6 min-w-full md:min-w-[16.5%] max-w-sm mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl shadow-lg flex items-center space-x-4 hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1">
      <div className="flex-shrink-0">
        <FiPaperclip className="h-12 w-12 text-white" />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-grey-500"></div>
        </div>
      ) : (
        <div>
          <h4 className="text-lg text-white font-semibold">
            Total Logs: {logs}
          </h4>
          <h4 className="text-lg text-white font-semibold">
            Success Logs: {successLogs}
          </h4>
          <h4 className="text-lg text-white font-semibold">
            Info Logs: {infoLogs}
          </h4>
          <h4 className="text-lg text-white font-semibold">
            Server Errors: {srErrorLogs}
          </h4>
          <h4 className="text-lg text-white font-semibold">
            Client Errors: {clErrorLogs}
          </h4>
        </div>
      )}
    </div>
  );
};

export default DashUserLogCard;
