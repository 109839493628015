import { processVideoData } from '../../utils/utils';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { getColor } from '../../utils/utils';

const EmotionPiechart = ({ videoEmotionAnalysis }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      labels: [],
      title: {
        text: 'Emotion Statistics',
        align: 'center',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    const processedData = processVideoData(videoEmotionAnalysis);
    updateChartData(processedData);
  }, [videoEmotionAnalysis]);

  const updateChartData = (processedData) => {
    const emotionCount = processedData.reduce((acc, data) => {
      acc[data.emotion] = (acc[data.emotion] || 0) + 1;
      return acc;
    }, {});

    const series = Object.values(emotionCount);
    const labels = Object.keys(emotionCount);
    const colors = labels.map((emotion) => getColor(emotion));

    setChartData({
      series,
      options: { ...chartData.options, labels, colors },
    });
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="500"
      />
    </div>
  );
};

export default EmotionPiechart;
