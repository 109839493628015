import React, { useState } from 'react';
import VideoStats from './VideoStats';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const StatisticsByUserEmail = () => {
  const [email, setEmail] = useState('');
  const [data, setData] = useState(); // State to store the results data
  const [isLoading, setIsLoading] = useState(''); // State to track loading status
  let url = `${apiBaseUrl}/api/results/filter/userEmail?email=${encodeURIComponent(
    email
  )}`;

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setData(null);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log('Raw Data:', data); // Log raw data for debugging
        const averagedData = processResults(data.data.results);
        // console.log('Processed Data:', averagedData); // Log processed data
        const totalAverages = calculateTotalAverages(averagedData);
        // console.log('Total Averages Data:', totalAverages); // Log total averages data
        setData(totalAverages); // or setData(totalAverages) based on what you need
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const processResults = (results) => {
    // console.log('Results received in processResults:', results); // Log data received in processResults

    return results.map((result) => {
      let ageLowTotal = 0,
        ageHighTotal = 0,
        maleCount = 0,
        femaleCount = 0,
        emotionsTotals = {},
        count = 0;

      if (result.result_details.length > 0) {
        const firstDetail = result.result_details[0];
        if (firstDetail.Face.Gender.Value === 'Male') {
          maleCount++;
        } else if (firstDetail.Face.Gender.Value === 'Female') {
          femaleCount++;
        }
      }

      result.result_details.forEach((detail) => {
        ageLowTotal += detail.Face.AgeRange.Low;
        ageHighTotal += detail.Face.AgeRange.High;
        detail.Face.Emotions.forEach((emotion) => {
          if (!emotionsTotals[emotion.Type]) {
            emotionsTotals[emotion.Type] = 0;
          }
          emotionsTotals[emotion.Type] += emotion.Confidence;
        });
        count++;
      });

      const averageEmotions = Object.fromEntries(
        Object.entries(emotionsTotals).map(([key, value]) => [
          key,
          value / count,
        ])
      );

      return {
        averageAgeLow: ageLowTotal / count,
        averageAgeHigh: ageHighTotal / count,
        maleCount,
        femaleCount,
        averageEmotions,
      };
    });
  };

  const calculateTotalAverages = (averagedData) => {
    let totalAgeLow = 0,
      totalAgeHigh = 0,
      totalMaleCount = 0,
      totalFemaleCount = 0,
      totalEmotions = {},
      resultCount = averagedData.length;

    averagedData.forEach((item) => {
      totalAgeLow += item.averageAgeLow;
      totalAgeHigh += item.averageAgeHigh;
      totalMaleCount += item.maleCount;
      totalFemaleCount += item.femaleCount;

      Object.entries(item.averageEmotions).forEach(([emotion, confidence]) => {
        if (!totalEmotions[emotion]) {
          totalEmotions[emotion] = 0;
        }
        totalEmotions[emotion] += confidence;
      });
    });

    const overallAverageEmotions = Object.fromEntries(
      Object.entries(totalEmotions).map(([key, value]) => [
        key,
        value / resultCount,
      ])
    );

    const totalAverages = {
      overallAverageAgeLow: totalAgeLow / resultCount,
      overallAverageAgeHigh: totalAgeHigh / resultCount,
      totalMaleCount,
      totalFemaleCount,
      overallAverageEmotions,
    };

    return totalAverages;
  };

  return (
    <>
      <div className="mt-20 flex w-2/3 mx-auto">
        <input
          type="text"
          placeholder="Enter a email to get the statistics / guest for guests statistics"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          className="input input-bordered flex-grow p-2 rounded-l-lg border border-r-0 border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        <button
          className="btn w-1/5 p-2 rounded-r-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : data ? (
          <VideoStats data={data} email={email} showAvgs={false} />
        ) : null}
      </div>
    </>
  );
};

export default StatisticsByUserEmail;
