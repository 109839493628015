import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import VideoCaptureComponent from '../Components/VideoCaptureComponent';
import { useAuth } from '../AuthContext'; // import the useAuth hook

const MonitoringPage = () => {
  const { isLoggedIn } = useAuth(); // get the logOut function and isLoggedIn state from useAuth

  return (
    <React.Fragment>
      {isLoggedIn && <Header />}
      {!isLoggedIn && (
        <>
          <p className="text-center text-2xl my-4">
            Give a try with an actual video from YouTube.
          </p>
          <Link to="/" className="py-2.5 px-4 rounded">
            <p className="text-center text-lg animate-pulse">
              Login to the system to see more details.
            </p>
          </Link>
        </>
      )}
      <VideoCaptureComponent />
      <Footer />
    </React.Fragment>
  );
};

export default MonitoringPage;
