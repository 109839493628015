import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaEye, FaFileExport } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import LogPopup from './LogPopup';
import FilterLogsComponent from './FilterLogsComponent';
import xmlbuilder from 'xmlbuilder';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Export = ({ onExport }) => (
  <button
    onClick={(e) => onExport(e)}
    className="flex items-center px-4 py-2.5 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
  >
    <FaFileExport className="inline mr-2" />
    Export Raw Data to XML
  </button>
);

const LogsDataTable = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedLog, setselectedLog] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [logType, setLogType] = useState('All');
  const [isLoading, setIsLoading] = useState(true); // State to track loading status

  useEffect(() => {
    let url = `${apiBaseUrl}/api/logs`;
    if (logType !== 'All') {
      url += `?log_type=${logType}`;
    }

    setIsLoading(true); // Start loading before the fetch request is sent
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setLogsData([...data.data.logs]);
        setIsLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error:', error);
        setLogsData([]);
        setIsLoading(false); // Stop loading if there's an error
      });
  }, [logType]);

  const columns = [
    {
      cell: (row) => (
        <button
          className="flex items-center px-4 py-2 text-md text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 shadow-md transition ease-in-out duration-300"
          onClick={() => handleButtonClick(row)}
        >
          <FaEye className="h-4 w-4 text-white mr-2" />
          <span>Details</span>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'ID',
      selector: (row) => row._id,
      sortable: true,
      width: '250px',
    },
    {
      name: 'Log Date',
      selector: (row) =>
        row.log_date
          ? new Date(row.log_date).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            })
          : 'N/A',
      sortable: true,
      width: '200px',
    },
    {
      name: 'Log Type',
      selector: (row) => row.log_details.log_type,
      sortable: true,
      width: '140px',
    },
    {
      name: 'Video Capturing',
      selector: (row) => row.refers_to,
      sortable: true,
      width: '300px',
    },
    {
      name: 'Log Message',
      selector: (row) => row.log_details.log_message,
      sortable: true,
      width: '500px',
    },
  ];

  const handleButtonClick = (id) => {
    setselectedLog(id);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    console.log('state', selectedRows);
  }, [selectedRows]);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = logsData.filter(
    (item) =>
      item.log_details.log_message &&
      item.log_details.log_message
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterLogsComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const downloadXML = (logsData) => {
    const root = xmlbuilder.create('root');

    logsData.forEach((row) => {
      const item = root.ele('item');
      Object.keys(row).forEach((key) => {
        item.ele(key, {}, row[key]);
      });
    });

    const xmlString = root.end({ pretty: true });

    // Create a downloadable blob
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'logs.xml');
    document.body.appendChild(link);
    link.click();
  };

  const actionsMemo = useMemo(
    () => (
      <>
        <label className="block text-sm font-medium text-gray-700">
          Select Log Type
        </label>
        <div className="relative inline-block w-1/2 mt-1">
          <select
            value={logType}
            onChange={(e) => setLogType(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-gradient-to-r from-blue-200 to-indigo-200 text-gray-700 hover:from-blue-300 hover:to-indigo-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm"
          >
            <option value="All">All</option>
            <option value="client_error">Client Error</option>
            <option value="server_error">Server Error</option>
            <option value="success">Success</option>
            <option value="info">Info</option>
          </select>
        </div>
        <Export onExport={() => downloadXML(logsData)} />
      </>
    ),
    [logType, logsData]
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor: '#202124', // dark background for the header
        color: 'white', // text color for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding
        paddingRight: '8px',
      },
    },
    pagination: {
      style: {
        padding: '20px', // Add padding around pagination
        color: '#004085', // Text color for pagination
        fontSize: '1.1rem', // Larger font for pagination
        justifyContent: 'center', // Center the pagination
      },
      pageButtonsStyle: {
        borderRadius: '50%', // Round the page buttons
        height: '40px', // Height of the page buttons
        width: '40px', // Width of the page buttons
        padding: '8px', // Padding within page buttons
        margin: '8px', // Margin around page buttons
        cursor: 'pointer', // Cursor to pointer on hover
        transition: 'background-color 0.3s', // Smooth transition for hover effect
        color: '#007bff', // Color of page button icons
        fill: '#007bff', // Fill color for SVG icons
      },
    },
  };

  return (
    <div className="w-full md:w-4/5 mx-auto p-4 mt-8 mb-20 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600 mb-4">
        Logs
      </h2>
      {!isLoading ? (
        <DataTable
          title=""
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          actions={actionsMemo}
          onSelectedRowsChange={handleChange}
        />
      ) : (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      )}
      {popupVisible && <LogPopup log={selectedLog} closePopup={closePopup} />}
    </div>
  );
};

export default LogsDataTable;
