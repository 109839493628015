import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import DashVideoInfoCard from '../Components/dashboard/DashVideoInfoCard';
import DashUserInfoCard from '../Components/dashboard/DashUserInfoCard';
import DashUserLogCard from '../Components/dashboard/DashLogsCard';
import DashVideoMonthCard from '../Components/dashboard/DashVideoMonthCard';
import DashGenderPieChartCard from '../Components/dashboard/DashGenderPieChartCard';
import { useAuth } from '../AuthContext';
import { Link } from 'react-router-dom';

const DashboardPage = () => {
  const { role } = useAuth();

  return (
    <React.Fragment>
      <Header />
      {role === 'admin' ? (
        <div className="px-8 py-12">
          <div className="flex flex-wrap justify-center gap-4">
            <DashVideoInfoCard />
            <DashUserInfoCard />
            <DashUserLogCard />
          </div>
          <div className="flex flex-wrap justify-center gap-4 my-8">
            <DashVideoMonthCard />
            <DashGenderPieChartCard />
          </div>
          {/* <div className="gap-4 my-8">
              <DashMapCard />
            </div> */}
        </div>
      ) : (
        //check your results if you are simple user
        <div className="py-12">
          <Link
            to={'/results'}
            className="content-center animate-pulse p-6 min-w-full md:min-w-[16.5%] max-w-sm mx-auto bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl shadow-lg flex items-center space-x-4 hover:shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1"
          >
            <div>
              <h4 className="text-lg text-white font-semibold">
                Check your results
              </h4>
            </div>
          </Link>
        </div>
      )}

      <Footer />
    </React.Fragment>
  );
};

export default DashboardPage;
