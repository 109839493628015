import React from 'react';
import Chart from 'react-apexcharts';
import { getColor } from '../../utils/utils';

const EmotionPiechart = ({ videoEmotionAnalysis }) => {
  const chartData = {
    series: Object.values(videoEmotionAnalysis),
    options: {
      labels: Object.keys(videoEmotionAnalysis),
      title: {
        text: 'Emotion Statistics',
        align: 'center',
      },
      colors: Object.keys(videoEmotionAnalysis).map((emotion) =>
        getColor(emotion.toLowerCase())
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="500"
      />
    </div>
  );
};

export default EmotionPiechart;
