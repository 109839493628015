import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize the isLoggedIn and role state based on localStorage value
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'true'
  );
  const [role, setRole] = useState(localStorage.getItem('role'));

  const logIn = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
    setRole(localStorage.getItem('role'));
  };

  const logOut = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    setRole(null);
    if (!isLoggedIn && shouldRedirectOnLogout) {
      window.location.href = '/'; // Redirect to login page
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, role, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
