import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import UsersDataTable from '../Components/users/UserDataTable';

const UsersPage = () => {
  return (
    <React.Fragment>
      <Header />
      <UsersDataTable />
      <Footer />
    </React.Fragment>
  );
};

export default UsersPage;
