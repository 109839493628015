import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().min(
    6,
    'Password should be at least 6 characters long'
  ),
});

const ProfilePage = () => {
  const [userData, setUserData] = useState({
    userName: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitStatus, setSubmitStatus] = useState('');
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true); // State to track loading status

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true); // Start loading before the fetch request is sent
        const response = await fetch(
          `${apiBaseUrl}/api/users/profile/${userId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        setUserData({ ...data.data.user, password: '' });
      } catch (err) {
        setIsLoading(false); // Stop loading if there's an error
        setError(err.message);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const updatedFields = {};
    if (values.email !== userData.email) updatedFields.email = values.email;
    if (values.password) updatedFields.password = values.password;

    try {
      const response = await fetch(
        `${apiBaseUrl}/api/users/profile/${userId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedFields),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update user data');
      }

      const updatedData = await response.json();
      // Update the user data but ensure password is not set
      setUserData({
        ...userData,
        email: updatedData.data.user.email,
        password: '',
      });
      // Reset the form with the new email and clear the password field
      resetForm({
        values: { ...values, email: updatedData.data.user.email, password: '' },
      });
      setSubmitStatus('Profile updated successfully!');
    } catch (err) {
      setError(err.message);
      setSubmitStatus('Failed to update profile.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div
        className="flex justify-center items-center bg-gray-100"
        style={{ height: 'calc(100vh - 100px)' }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : (
          <div className="w-full max-w-xs">
            <Formik
              initialValues={userData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="userName"
                    >
                      Username
                    </label>
                    <Field
                      type="text"
                      name="userName"
                      disabled
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="password"
                    >
                      New Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                      className={`w-full mx-auto px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-purple-600 hover:to-purple-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md ${
                        isSubmitting || !isValid || !dirty
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      }`}
                    >
                      Update Profile
                    </button>
                  </div>
                  {submitStatus && (
                    <div className="text-center text-green-500 mt-3">
                      {submitStatus}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ProfilePage;
