import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ResultsDataTable from "../Components/results/ResultsDataTable";

const ResultsPage = () => {
    return (
        <React.Fragment>
            <Header />
            <ResultsDataTable />
            <Footer />
        </React.Fragment>
    );
  };
  
  export default ResultsPage;