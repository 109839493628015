import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import StatisticsByVideoId from '../Components/statistics/StatisticsByVideoId';

const StatisticsByVideoIdPage = () => {
  return (
    <React.Fragment>
      <Header />
      <StatisticsByVideoId />
      <Footer />
    </React.Fragment>
  );
};

export default StatisticsByVideoIdPage;
