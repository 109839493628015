import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaEye, FaFileExport } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import VideoDetailsPopup from './VideoDetailsPopup';
import FilterComponent from './FilterComponent';
import { useAuth } from '../../AuthContext';
import xmlbuilder from 'xmlbuilder';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Export = ({ onExport }) => (
  <button
    onClick={(e) => onExport(e)}
    className="flex items-center px-4 py-2 mr-2 text-sm font-semibold rounded-lg bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring focus:ring-green-300 focus:ring-offset-2 transition ease-in-out duration-300 shadow-md"
  >
    <FaFileExport className="inline mr-2" />
    Export Raw Data to XML
  </button>
);

const ResultsDataTable = () => {
  const { role } = useAuth(); // Use the useAuth hook to get the user's role
  const [resultsData, setResultsData] = useState([]);
  const userEmail = localStorage.getItem('userEmail');
  const [isLoading, setIsLoading] = useState(true); // State to track loading status

  useEffect(() => {
    let URL = `${apiBaseUrl}/api/results/`;

    if (role !== 'admin' && userEmail) {
      URL = `${apiBaseUrl}/api/results/filter/userEmail?email=${encodeURIComponent(
        userEmail
      )}`;
    }

    setIsLoading(true); // Start loading before the fetch request is sent
    fetch(URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.data.results);
        setResultsData(data.data.results);
        setIsLoading(false); // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error:', error);
        setResultsData([]);
        setIsLoading(false); // Stop loading if there's an error
      });
  }, [userEmail]);

  const columns = [
    {
      cell: (row) => (
        <button
          className="flex items-center px-4 py-2 text-md text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 shadow-md transition ease-in-out duration-300"
          onClick={() => handleButtonClick(row)}
        >
          <FaEye className="h-4 w-4 text-white mr-2" />
          <span>Details</span>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'ID',
      selector: (row) => row._id,
      sortable: true,
      width: '250px',
    },
    {
      name: 'Result Date',
      selector: (row) =>
        row.result_date
          ? new Date(row.result_date).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            })
          : 'N/A',
      sortable: true,
      width: '200px',
    },
    {
      name: 'Refers To',
      selector: (row) => row.refers_to,
      sortable: true,
    },
    {
      name: 'Video URL',
      selector: (row) => row.videoData.videoUrl,
      sortable: true,
    },
    {
      name: 'Video ID',
      selector: (row) => row.videoData.videoId,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Username',
      selector: (row) => row.videoData.username,
      sortable: true,
      width: '150px',
    },
  ];

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedResult, setselectedResult] = useState(null);

  const handleButtonClick = (user) => {
    setselectedResult(user);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    console.log('state', selectedRows);
  }, [selectedRows]);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = resultsData.filter(
    (item) =>
      item.refers_to &&
      item.refers_to.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const downloadXML = (resultsData) => {
    const root = xmlbuilder.create('root');

    resultsData.forEach((row, index) => {
      const item = root.ele('item');
      Object.keys(row).forEach((key) => {
        item.ele(key, {}, row[key]);
      });
    });

    const xmlString = root.end({ pretty: true });

    // Create a downloadable blob
    const blob = new Blob([xmlString], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'results.xml');
    document.body.appendChild(link);
    link.click();
  };

  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadXML(resultsData)} />,
    []
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor: '#202124', // dark background for the header
        color: 'white', // text color for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding
        paddingRight: '8px',
      },
    },
    pagination: {
      style: {
        padding: '20px', // Add padding around pagination
        color: '#004085', // Text color for pagination
        fontSize: '1.1rem', // Larger font for pagination
        justifyContent: 'center', // Center the pagination
      },
      pageButtonsStyle: {
        borderRadius: '50%', // Round the page buttons
        height: '40px', // Height of the page buttons
        width: '40px', // Width of the page buttons
        padding: '8px', // Padding within page buttons
        margin: '8px', // Margin around page buttons
        cursor: 'pointer', // Cursor to pointer on hover
        transition: 'background-color 0.3s', // Smooth transition for hover effect
        color: '#007bff', // Color of page button icons
        fill: '#007bff', // Fill color for SVG icons
      },
    },
  };

  return (
    <div className="w-full md:w-4/5 mx-auto p-4 mt-8 mb-20 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600 mb-4">
        Video Analysis Results List
      </h2>
      {!isLoading ? (
        <DataTable
          title=""
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          actions={actionsMemo}
          onSelectedRowsChange={handleChange}
        />
      ) : (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      )}

      {popupVisible && (
        <VideoDetailsPopup
          VideoDetails={selectedResult}
          closePopup={closePopup}
        />
      )}
    </div>
  );
};

export default ResultsDataTable;
