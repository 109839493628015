import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import Webcam from 'react-webcam';
import { logEvent } from '../utils/utils.js';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function randomString(length = 5) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Custom hook for handling video recording
const useVideoRecorder = (webcamRef) => {
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [capturedVideo, setCapturedVideo] = useState(null);

  const startRecording = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      const stream = webcamRef.current.stream;
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: 'video/webm',
      });
      let chunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setCapturedVideo(URL.createObjectURL(blob));
      };
      mediaRecorderRef.current.start();
      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  return { recording, capturedVideo, startRecording, stopRecording };
};

const randomVideo = [
  { videoUrl: 'https://www.youtube.com/watch?v=snYu2JUqSWs' },
  { videoUrl: 'https://www.youtube.com/watch?v=xuP4g7IDgDM' },
  { videoUrl: 'https://www.youtube.com/watch?v=Rciv-Vb299Y' },
  { videoUrl: 'https://www.youtube.com/watch?v=kt2D7xl06mk' },
  { videoUrl: 'https://www.youtube.com/watch?v=Xocr00KRisI' },
  { videoUrl: 'https://www.youtube.com/watch?v=uaVxC4syxPY' },
  { videoUrl: 'https://www.youtube.com/watch?v=oaJ3hRoV84Y' },
  { videoUrl: 'https://www.youtube.com/watch?v=rKwTIqEnJDc' },
  { videoUrl: 'https://www.youtube.com/watch?v=kQ8nie2fahc' },
  { videoUrl: 'https://www.youtube.com/watch?v=kQ8nie2fahc' },
];

const VideoCaptureComponent = () => {
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [email, setEmail] = useState(
    localStorage.getItem('userEmail') ? localStorage.getItem('userEmail') : ''
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [possibleUser, setPossibleUser] = useState('guest');
  const webcamRef = useRef(null);
  const [webcamActive, setWebcamActive] = useState(false);
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [videoFileName, setVideoFileName] = useState('default-name.webm');
  const [isSubmitting, setIsSubmitting] = useState(false); // variable for the spinner
  const [successfulSubmission, setSuccessfulSubmission] = useState(false); // new state variable

  const { recording, capturedVideo, startRecording, stopRecording } =
    useVideoRecorder(webcamRef);

  useEffect(() => {
    setSuccessfulSubmission(false);
    if (capturedVideo) {
      assignVideoFileName();
    }
  }, [capturedVideo]);

  useEffect(() => {
    // Request for camera and microphone permissions
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setPermissionsGranted(true);
        stream.getTracks().forEach((track) => track.stop()); // Stop using the stream
      })
      .catch((error) => {
        console.error('Permissions denied:', error);
        logEvent(
          'client_error',
          'Permissions for microphone and camera are denied',
          videoFileName
        );
        setPermissionsGranted(false);
      });
  }, []);

  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
    setSuccessfulSubmission(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (isValidEmail(e.target.value)) {
      setPossibleUser(e.target.value);
    } else {
      setPossibleUser(null);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleVideoPlay = (event) => {
    const playerState = event.target.getPlayerState();
    // console.log(`YouTube Player State: ${playerState}`);

    if (playerState === YT.PlayerState.PLAYING && !recording) {
      console.log('Starting recording due to video play');
      setWebcamActive(true);
      setTimeout(startRecording, 1000); // delay may be adjusted
    }
  };

  const handleVideoEnd = () => {
    stopRecording();
    setWebcamActive(false);
  };

  const assignVideoFileName = () => {
    // Get video ID from youtubeUrl
    const videoId = youtubeUrl.split('v=')[1];

    // Create timestamp for use in the video
    const date = new Date();
    const timestamp = date.toISOString().replace(/[-:T]/g, '').slice(0, -5);

    // Check if the user is logged in to the browser, else assume as a guest
    let username = email ? email : 'guest';

    // Create random string to make the video name more unique
    const randomStr = randomString();

    // Construct the video file name
    setVideoFileName(
      `video-${timestamp}-${videoId}-${username}-${randomStr}.webm`
    );
  };

  const handleVideoSubmit = async () => {
    // console.log('Submit button clicked');
    setIsSubmitting(true); // Start submission, show spinner

    if (capturedVideo) {
      try {
        // Fetch the video blob data from the capturedVideo URL
        const response = await fetch(capturedVideo);
        const blob = await response.blob();

        // Create a FormData object and append the video blob
        const formData = new FormData();
        assignVideoFileName();

        // Add additional data to the form
        const videoData = {
          videoUrl: youtubeUrl,
          videoId: youtubeUrl.split('v=')[1],
          username: email ? email : 'guest',
          videoFileName: videoFileName,
        };

        formData.append('video', blob, videoFileName);
        formData.append('videoData', JSON.stringify(videoData));

        // Post the video data to the API using fetch
        const uploadResponse = await fetch(
          `${apiBaseUrl}/api/videoConvertAndUpload`,
          {
            method: 'POST',
            body: formData,
            // Note: Do not set 'Content-Type' header when using FormData.
            // The browser will set it automatically with the correct boundary.
          }
        );

        // Check if the request was successful
        if (!uploadResponse.ok) {
          logEvent(
            'client_error',
            'Error uploading video to the server',
            videoFileName
          );
          throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
        }

        // Handle the response
        const result = await uploadResponse.json();
        console.log('Video uploaded successfully:', result);
        setSuccessfulSubmission(true);
        logEvent(
          'success',
          'Success submitting form data from the client:',
          videoFileName
        );

        // If the checkbox is checked, make an additional POST request for user creation
        if (isCheckboxChecked) {
          const userData = {
            email: email,
          };

          const userCreationResponse = await fetch(
            `${apiBaseUrl}/api/users/createUserFromClient`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(userData),
            }
          );

          if (!userCreationResponse.ok) {
            throw new Error(
              `HTTP error! Status: ${userCreationResponse.status}`
            );
          }

          const userCreationResult = await userCreationResponse.json();
          console.log('User created successfully:', userCreationResult);
          logEvent(
            'success',
            'Success creating a user from the client:',
            videoFileName
          );
        }
        // Reset captured video
        setCapturedVideo(null);
        setIsSubmitting(false); // End submission, hide spinner
      } catch (error) {
        logEvent('client_error', 'Error submitting form data:', videoFileName);
        console.error('Error submitting form data', error);
        setIsSubmitting(false); // In case of error, end submission, hide spinner
        // You can handle specific errors based on error.message here
      }
    }
  };

  if (!permissionsGranted) {
    return (
      <div className="text-center text-xl my-4 bg-red-400">
        Waiting for camera and microphone permissions... If are disabled, enable
        them and after reload the page to start the magic!
      </div>
    );
  }

  const handleRandomVideo = () => {
    const randomVideoIndex = Math.floor(Math.random() * randomVideo.length);
    setYoutubeUrl(randomVideo[randomVideoIndex].videoUrl);
    setSuccessfulSubmission(true);
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="flex w-full bg-slate-500 p-2 rounded-md">
          <div className="w-[99%] mr-2">
            <input
              type="text"
              placeholder="Enter YouTube URL."
              value={youtubeUrl}
              onChange={handleYoutubeUrlChange}
              className="input input-bordered w-[70%] m-2"
            />
            <span className="text-white text-sm">or watch</span>
            <button
              className="bg-white border border-blue-600 hover:bg-slate-200 px-2 m-2 w-[15%]"
              onClick={() => handleRandomVideo()}
            >
              Random Video
            </button>
          </div>
          {!localStorage.getItem('userEmail') && (
            <div className="w-[70%] mr-4">
              <input
                type="email"
                placeholder="Enter Email (optional) or proceed as guest."
                value={email}
                onChange={handleEmailChange}
                className="input input-bordered w-full m-2"
              />
              {possibleUser && possibleUser !== 'guest' && (
                <div>
                  <input
                    type="checkbox"
                    placeholder="Enter Email (optional) or proceed as guest."
                    value={possibleUser}
                    onChange={handleCheckboxChange}
                    id="userCheckbox"
                    className="ml-2"
                  />
                  <label
                    htmlFor="userCheckbox"
                    className="text-white mx-2 font-semibold text-sm"
                  >
                    Do you want to create a user, if you currently have not and
                    send the info to your email?
                  </label>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex">
          {youtubeUrl && (
            <div className="flex flex-col text-left w-full my-4">
              <h4>YouTube video</h4>
              <YouTube
                videoId={youtubeUrl.split('v=')[1]}
                onStateChange={handleVideoPlay}
                opts={{
                  height: '390',
                  width: '640',
                  playerVars: { autoplay: 0 },
                }}
                onPlay={handleVideoPlay}
                onEnd={handleVideoEnd}
              />
            </div>
          )}

          {webcamActive ? (
            <div className="flex flex-col text-left w-full my-4">
              <h4>Your video capturing watching the video</h4>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="max-w-[780px] max-h-[390px]"
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center text-left w-full my-4">
              {capturedVideo && !successfulSubmission && (
                <div className="flex flex-col justify-center text-left w-full my-4">
                  <div className="flex text-center w-full py-6">
                    <a
                      href={capturedVideo}
                      download={videoFileName}
                      className="btn btn-secondary p-4 w-1/2 mx-auto bg-slate-900 rounded-md font-bold text-white hover:bg-slate-700 hover:text-gray-200"
                      type="button"
                    >
                      Download Video
                    </a>
                  </div>

                  <div className="flex text-center w-full pb-6">
                    <button
                      onClick={handleVideoSubmit}
                      className="animate-pulse btn btn-secondary p-4 w-1/2 mx-auto bg-slate-500 rounded-md font-bold text-white hover:bg-slate-700 hover:text-gray-200"
                      type="button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          {/* <div className="inline-block animate-spin rounded-full h-6 w-6 border-b-2 border-white mr-2"></div> */}
                          <span className="text-opacity-75">Submitting...</span>
                        </>
                      ) : (
                        'Submit Video for Analysis'
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isSubmitting && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center bg-black opacity-50 z-[9999999999999999999]">
          <div className="flex animate-spin rounded-full h-20 w-20 my-5 border-b-2 border-white"></div>
          <span className="text-white">Uploading and Analysis...</span>
          <span className="text-white my-4 text-[12px]">
            Thats a heavy process may take long time. Hold tight!
          </span>
        </div>
      )}
    </>
  );
};

export default VideoCaptureComponent;
