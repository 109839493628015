import React, { useState } from 'react';
import { HiOutlineLogout, HiOutlineCog } from 'react-icons/hi';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import Sidebar from './Sidebar';
import { useAuth } from '../AuthContext';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const { logOut } = useAuth(); // get the logOut function and isLoggedIn state from useAuth
  const userName = localStorage.getItem('userName'); // Retrieve the username from local storage

  return (
    <header className="sticky top-0 z-50 bg-gradient-to-r from-blue-500 to-indigo-600 shadow-lg">
      <div className="flex justify-between w-100% py-4 px-6">
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleSidebar}
            className="text-white focus:outline-none"
          >
            {isOpen ? (
              <AiOutlineClose size={30} />
            ) : (
              <AiOutlineMenu size={30} />
            )}
          </button>
          <Link to="/dashboard">
            <img src={logo} alt="Logo" className="h-10 w-10" />
          </Link>
          <h1 className="text-xl font-bold text-white">Emotion Analysis</h1>
        </div>
        <div className="flex items-center space-x-4">
          {userName && (
            <div className="text-white text-sm mr-4">
              <span className="font-semibold">Hello, </span>
              <span className="font-semibold">{userName}</span>
            </div>
          )}
          <Link to="/profile">
            <HiOutlineCog
              className="text-white hover:text-gray-300 cursor-pointer"
              size={30}
            />
          </Link>
          <HiOutlineLogout
            className="text-white hover:text-gray-300 cursor-pointer"
            size={30}
            onClick={() => logOut()}
          />
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 duration-500 ease-in-out"
          onClick={closeSidebar}
        ></div>
      )}
      <div
        className={`absolute w-full transform transition-transform duration-500 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } z-50`}
      >
        <Sidebar />
      </div>
    </header>
  );
};

export default Header;
