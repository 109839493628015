import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import LogsDataTable from '../Components/logs/LogsDataTable';

const LogPage = () => {
  return (
    <React.Fragment>
      <Header />
      <LogsDataTable />
      <Footer />
    </React.Fragment>
  );
};

export default LogPage;
