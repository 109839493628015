import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const DashGenderPieChartCard = () => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [chartData, setChartData] = useState({
    series: [],
    labels: [],
  });

  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch request is sent
    fetch(`${apiBaseUrl}/api/results/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          processGenderData(data.data.results);
          setIsLoading(false); // Stop loading after data is fetched
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Stop loading if there's an error
      });
  }, []);

  const processGenderData = (data) => {
    const genderCount = { Male: 0, Female: 0, Unknown: 0 };

    // gender from every timestamp emotional change in a video
    // data.forEach((result) => {
    //   result.result_details.forEach((detail) => {
    //     const gender = detail.Face?.Gender?.Value || 'Unknown';
    //     genderCount[gender] = (genderCount[gender] || 0) + 1;
    //   });
    // });

    // gender from the first timestamp emotional in a video
    data.forEach((result) => {
      if (result.result_details.length > 0) {
        const gender =
          result.result_details[0].Face?.Gender?.Value || 'Unknown';
        genderCount[gender]++;
      }
    });

    setChartData({
      series: Object.values(genderCount),
      labels: Object.keys(genderCount),
    });
  };

  return (
    <div className="p-6 max-w-screen-md mx-auto bg-white rounded-xl shadow-md hover:bg-gray-100 transition duration-300 w-full">
      <h4 className="text-lg text-gray-500 font-semibold">
        Gender Distribution
      </h4>
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <div className="mt-4">
          <Chart
            options={{ labels: chartData.labels }}
            series={chartData.series}
            type="pie"
            width="600"
          />
        </div>
      )}
    </div>
  );
};

export default DashGenderPieChartCard;
